
import TimelapseSaveDialogFormatStep from "@/components/timelapse/TimelapseSaveDialogFormatStep"
import TimelapseSaveDialogEffectsStep from "@/components/timelapse/TimelapseSaveDialogEffectsStep"
import TimelapseSaveDialogConfirmStep from "@/components/timelapse/TimelapseSaveDialogConfirmStep"
import TimelapseSaveDialogFinishStep from "@/components/timelapse/TimelapseSaveDialogFinishStep"
import { mapStores } from "pinia"
import { useTimelapseStore } from "@/stores/timelapse"
import { AnalyticsEvent, TimelapseExportStep } from "@evercam/shared/types"
import { invertEnum } from "@evercam/shared/utils"

export default {
  name: "TimelapseSaveDialog",
  components: {
    TimelapseSaveDialogFormatStep,
    TimelapseSaveDialogEffectsStep,
    TimelapseSaveDialogConfirmStep,
    TimelapseSaveDialogFinishStep,
  },
  props: {
    endMessage: {
      type: String,
      default: "",
    },
    timelapseCreationError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TimelapseExportStep,
    }
  },
  computed: {
    ...mapStores(useTimelapseStore),
  },
  watch: {
    saveDialog(val) {
      if (!val) {
        if (this.timelapseStore.stepper === 4) {
          this.clickedNext()
        }
      }
    },
    "timelapseStore.stepper"(val) {
      const stepNames = invertEnum(TimelapseExportStep)
      this.$analytics.saveEvent(AnalyticsEvent.timelapseExportSelectStep, {
        step: stepNames[val],
      })
    },
  },
  methods: {
    clickedNext() {
      this.timelapseStore.saveDialog = false
      this.timelapseStore.partialUnsetProperties()
      this.$emit("clearEndMessage")
    },
  },
}
