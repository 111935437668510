
import { mapStores } from "pinia"
import { useTimelapseStore } from "@/stores/timelapse"
import { AnalyticsEvent, TimelapseDuration } from "@evercam/shared/types"

export default {
  name: "TimelapseSaveDialogFormatStep",
  data() {
    return {
      durations: [
        {
          value: TimelapseDuration._30Seconds,
          label: this.$t("content.timelapse.30_seconds"),
        },
        {
          value: TimelapseDuration._60Seconds,
          label: this.$t("content.timelapse.60_seconds"),
        },
        {
          value: TimelapseDuration._90Seconds,
          label: this.$t("content.timelapse.90_seconds"),
        },
      ],
    }
  },
  computed: {
    ...mapStores(useTimelapseStore),
  },
  watch: {
    "timelapseStore.selectedDuration"(value) {
      this.$analytics.saveEvent(AnalyticsEvent.timelapseSelectOption, {
        option: "duration",
        value,
      })
    },
  },
}
