
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { AnalyticsEvent } from "@evercam/shared/types"
export default {
  name: "AddProjectLogoDialog",
  props: {
    projectId: {
      type: [String, undefined],
      default: undefined,
    },
    isIconButton: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      addLogoDialog: false,
      newLogo: null,
      isLoading: false,
    }
  },
  watch: {
    addLogoDialog(visible) {
      this.$analytics.saveEvent(
        AnalyticsEvent.timelapseToggleUploadLogoDialog,
        { visible }
      )
    },
  },
  methods: {
    async addLogo() {
      try {
        this.$analytics.saveEvent(AnalyticsEvent.timelapseUploadLogo)
        this.isLoading = true
        const formData = new FormData()
        formData.append("name", this.newLogo.name)
        formData.append("logo", this.newLogo)
        await EvercamApi.projects.createLogo(this.projectId, formData)
        this.$emit("logo-added")
        this.$notifications.success(this.$t("content.logo.add_success"))
        this.addLogoDialog = false
        this.newLogo = null
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.logo.add_error"),
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
