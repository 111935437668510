
import Vue from "vue"
import { debounce, invertEnum } from "@evercam/shared/utils"
import CtkDateTimePicker from "@evercam/shared/components/CtkDateTimePicker"
import schedules, {
  subtractOneHourFromEndIntervals,
} from "@evercam/shared/constants/schedules"
import Schedule from "@/components/Schedule"
import TimelapseSaveDialog from "@/components/timelapse/TimelapseSaveDialog"
import TimelapseHelpDialog from "@/components/timelapse/TimelapseHelpDialog"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import {
  TimelapseCreationRequestPayload,
  TimelapsePeriod,
  TimelapseSchedule,
  TimelapseScheduleType,
} from "@evercam/shared/types/timelapse"
import AddProjectLogoDialog from "@evercam/shared/components/AddProjectLogoDialog"
import { mapStores } from "pinia"
import { useMediaHubStore } from "@/stores/mediaHub"
import { useTimelapseStore } from "@/stores/timelapse"
import { useSnapshotStore } from "@/stores/snapshots"
import { useLayoutStore } from "@/stores/layout"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { AnalyticsEvent } from "@evercam/shared/types/analytics"

type Period = {
  text: string
  disabled: boolean
  value: TimelapsePeriod
}

type ScheduleOption = {
  label: string
  value: TimelapseScheduleType
  description?: string
}

export default Vue.extend({
  name: "TimelapseSidebar",
  components: {
    CtkDateTimePicker,
    TimelapseSaveDialog,
    TimelapseHelpDialog,
    AddProjectLogoDialog,
    Schedule,
  },
  props: {
    logoPosition: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      scheduleType: TimelapseScheduleType.Continuous,
      timelapseScheduleType: TimelapseScheduleType,
      scheduleDialog: false,
      customSchedule: schedules.WORKING_HOURS,
      logos: [],
      workingScheduleDisabledHours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      workingScheduleDisabledDays: [1, 7],
      logo: null,
      lastLogo: null,
      isDatePickerError: 0,
      endMessage: "",
      timelapseCreationError: false,
      containerStyle: {},
    }
  },
  computed: {
    ...mapStores(
      useMediaHubStore,
      useTimelapseStore,
      useSnapshotStore,
      useLayoutStore,
      useCameraStore,
      useProjectStore
    ),
    isWeekend() {
      return this.workingScheduleDisabledDays.includes(this.$moment().weekday())
    },
    periods(): Period[] {
      return [
        {
          text: this.$t("content.timelapse.24_hours") as string,
          disabled:
            this.$moment(this.$moment()).diff(this.latestDate, "days") > 1 &&
            !this.isWeekend,
          value: TimelapsePeriod.DAY,
        },
        {
          text: this.$t("content.timelapse.7_days") as string,
          disabled:
            this.$moment(this.$moment()).diff(this.latestDate, "days") > 7,
          value: TimelapsePeriod.WEEK,
        },
        {
          text: this.$t("content.timelapse.30_days") as string,
          disabled:
            this.$moment(this.$moment()).diff(this.latestDate, "days") > 30,
          value: TimelapsePeriod.MONTH,
        },
        {
          text: this.$t("content.timelapse.whole_project") as string,
          disabled: false,
          value: TimelapsePeriod.WHOLE_PROJECT,
        },
        {
          text: this.$t("content.timelapse.custom_period") as string,
          disabled: false,
          value: TimelapsePeriod.CUSTOM,
        },
      ]
    },
    currentDate() {
      return this.$moment(new Date())
        .tz(this.projectStore.selectedProjectTimezone)
        .format("YYYY-MM-DD HH:mm")
    },
    oldestDate(): string | Date {
      return (
        this.$moment(this.snapshotStore.oldestSnapshotTimestamp)
          .tz(this.projectStore.selectedProjectTimezone)
          .format("YYYY-MM-DD HH:mm") || this.currentDate
      )
    },
    latestDate(): string | Date {
      return (
        this.$moment(this.snapshotStore.latestSnapshotTimestamp)
          .tz(this.projectStore.selectedProjectTimezone)
          .format("YYYY-MM-DD HH:mm") || this.currentDate
      )
    },
    customPeriodInterval() {
      return {
        from: this.timelapseStore.fromDatetime,
        to: this.timelapseStore.toDatetime,
      }
    },
    showCustomPeriodInputs() {
      return this.timelapseStore.selectedPeriod === TimelapsePeriod.CUSTOM
    },
    defaultPeriod(): Period {
      return this.periods.find((period) => !period.disabled)
    },
    scheduleOptions(): ScheduleOption[] {
      return [
        {
          label: this.$t("content.timelapse.continuous") as string,
          value: TimelapseScheduleType.Continuous,
        },
        {
          label: this.$t("content.timelapse.working_hours") as string,
          value: TimelapseScheduleType.WorkingHours,
          description: this.$t("content.timelapse.working_hours_description"),
        },
        {
          label: this.$t("content.timelapse.custom") as string,
          value: TimelapseScheduleType.Custom,
        },
      ]
    },
    datePickerErrorMessage(): string | number {
      switch (this.isDatePickerError) {
        case 0:
          return ""
        case 1:
          return "Please select a valid range, the start date must not be later than the end date."
        case 2:
          return "No available snapshots for the selected period. Please change the Period or the Schedule."
      }

      return 0
    },
    isWorkingSchedule(): boolean {
      return this.scheduleType === TimelapseScheduleType.WorkingHours
    },
    getDisabledHours(): string[] {
      return this.scheduleType === TimelapseScheduleType.WorkingHours
        ? this.workingScheduleDisabledHours
        : []
    },
  },
  watch: {
    logo: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("change-logo", this.logo?.url)
      },
    },
    "timelapseStore.selectedPeriod": {
      immediate: true,
      handler(value, oldValue) {
        this.onPeriodChange()
        this.$setTimeout(this.onResize, 100)
        if (oldValue) {
          const periodByValue = invertEnum(TimelapsePeriod)
          this.saveAnalyticsSelectOptionEvent("period", periodByValue[value])
        }
      },
    },
    scheduleType(schedule) {
      switch (schedule) {
        case TimelapseScheduleType.Custom:
          this.scheduleDialog = true
          break
        case TimelapseScheduleType.Continuous:
          this.timelapseStore.schedule = schedules.CONTINUOUS
          break
        case TimelapseScheduleType.WorkingHours:
          this.timelapseStore.schedule = schedules.WORKING_HOURS
          if (
            this.timelapseStore.selectedPeriod === TimelapsePeriod.DAY &&
            this.isWeekend
          ) {
            this.timelapseStore.selectedPeriod = TimelapsePeriod.WEEK
          }
          break
      }

      this.saveAnalyticsSelectOptionEvent("schedule", schedule)
    },
    scheduleDialog(visible) {
      if (!visible) {
        this.$analytics.saveEvent(
          AnalyticsEvent.timelapseToggleCustomScheduleDialog,
          {
            visible,
          }
        )
      }
    },
    "timelapseStore.schedule": {
      immediate: true,
      handler() {
        this.submitChange()
      },
    },
    customPeriodInterval(interval) {
      if (this.showCustomPeriodInputs) {
        this.$analytics.saveEvent(
          AnalyticsEvent.timelapseSelectCustomPeriod,
          interval
        )
      }
    },
    "timelapseStore.saveDialog"(visible) {
      this.$analytics.saveEvent(AnalyticsEvent.timelapseToggleExportDialog, {
        visible,
      })
    },
  },
  created() {
    this.fetchLogo()
  },
  mounted() {
    this.$addEventListener("fullscreenchange", this.escFullscreen)
    this.$addEventListener("webkitfullscreenchange", this.escFullscreen)
    this.$addEventListener("mozfullscreenchange", this.escFullscreen)
    this.$addEventListener("MSFullscreenChange", this.escFullscreen)
    this.$nextTick(this.onResize)
    this.timelapseStore.selectedPeriod = this.defaultPeriod.value
  },
  methods: {
    toggleRightSidebarOverflow() {
      const rightSidebar = document.querySelector(".right-sidebar__wrapper")
      rightSidebar?.classList.toggle("overflow-only-y-scroll")
      rightSidebar?.classList.toggle("overflow-x-visible")
      const rightSidebarDrawerButton = document.querySelector(
        ".right-sidebar__drawer-button"
      )
      rightSidebarDrawerButton?.classList.toggle(
        "right-sidebar__drawer-button--hide"
      )
    },
    getFormattedDate(date) {
      return this.$moment
        .tz(date, this.cameraStore.selectedCameraTimezone)
        .format()
    },
    onShowPicker() {
      this.toggleRightSidebarOverflow()
      if (this.$device.isIos) {
        this.layoutStore.isFullscreen = true
      }
    },
    onHidePicker() {
      this.toggleRightSidebarOverflow()
      if (this.$device.isIos) {
        this.layoutStore.isFullscreen = false
      }
    },
    async fetchLogo() {
      try {
        const logos = await EvercamApi.projects.getLogos(
          this.projectStore.selectedProjectExid
        )
        this.logos = [{ id: 0, name: "None", url: false }, ...logos]
      } catch (error) {
        this.$errorTracker.save(error)
      }
    },
    changeLogo(value) {
      this.lastLogo = value
      this.saveAnalyticsSelectOptionEvent("logo", value?.name)
    },
    escFullscreen() {
      if (!document.fullscreenElement) {
        this.logo = null
        this.$setTimeout(() => {
          this.logo = this.lastLogo
        }, 200)
      }
    },
    validateDateRange() {
      const customMinutesRange = this.$moment(
        this.timelapseStore.toDatetime
      ).diff(this.$moment(this.timelapseStore.fromDatetime), "minutes")
      // Tests if the difference between the two dates is negative
      if (customMinutesRange <= 0) {
        this.isDatePickerError = 1
      }
      // Good to go
      else {
        this.isDatePickerError = 0
      }
    },
    onChangeToTime(value) {
      this.timelapseStore.toDatetime = this.$moment
        .tz(value, this.cameraStore.selectedCameraTimezone)
        .format()
      this.validateDateRange()
    },
    onChangeFromTime(value) {
      this.timelapseStore.fromDatetime = this.$moment
        .tz(value, this.cameraStore.selectedCameraTimezone)
        .format()
      this.validateDateRange()
    },
    saveAnalyticsSelectOptionEvent(optionType, optionValue) {
      this.$analytics.saveEvent(AnalyticsEvent.timelapseSelectOption, {
        option: optionType,
        value: optionValue,
      })
    },
    onPeriodChange() {
      const period = this.periods.find(
        (period) => period.value == this.timelapseStore.selectedPeriod
      )

      if (period?.disabled) {
        return
      }
      switch (this.timelapseStore.selectedPeriod) {
        case TimelapsePeriod.CUSTOM:
          this.validateDateRange()
          break
        case TimelapsePeriod.WHOLE_PROJECT:
          this.isDatePickerError = 0
          this.timelapseStore.fromDatetime = this.$moment(this.oldestDate)
            .utc()
            .format()
          this.validateDateRange()
          this.submitChange()
          break
        default:
          this.isDatePickerError = 0
          var date = this.$moment
            .utc(this.currentDate)
            .subtract({ days: this.timelapseStore.selectedPeriod })
            .format()
          this.timelapseStore.fromDatetime = date
          this.timelapseStore.toDatetime = this.$moment
            .utc(this.currentDate)
            .format()
          this.validateDateRange()
          this.submitChange()
          break
      }
    },
    saveSchedule() {
      this.scheduleDialog = !this.scheduleDialog
      this.timelapseStore.schedule = this.customSchedule as TimelapseSchedule
      this.$analytics.saveEvent(AnalyticsEvent.timelapseSaveCustomSchedule, {
        schedule: this.customSchedule,
      })
    },
    async saveToMediaHub() {
      this.$analytics.saveEvent(AnalyticsEvent.timelapseSaveToMediaHub)
      const timezone = this.cameraStore.selectedCameraTimezone

      let payload: TimelapseCreationRequestPayload = {
        title: this.timelapseStore.title,
        duration: parseInt(this.timelapseStore.selectedDuration),
        cameraExid: this.cameraStore.selectedCameraExid,
        fromDatetime: this.$moment
          .tz(this.timelapseStore.fromDatetime, timezone)
          .toISOString(true),
        toDatetime: this.$moment
          .tz(this.timelapseStore.toDatetime, timezone)
          .toISOString(true),
        schedule: subtractOneHourFromEndIntervals(
          this.timelapseStore.schedule
        ) as TimelapseSchedule,
        videoOptions: {
          removeTimestamp: this.timelapseStore.removeTimestamp,
          smoothTransition: this.timelapseStore.smoothTransition,
          evercamWatermark: this.layoutStore.isEvercamWatermark,
        },
      }
      if (this.logo && this.logo.name !== "None") {
        const ensureValidLogoSize = (size) => {
          size = Math.abs(Number.parseFloat(size.toFixed(5)) || 0.05)

          return size > 0.5 ? 0.5 : size
        }
        payload = {
          ...payload,
          logo: {
            id: this.logo.id,
            x: Number.parseFloat(this.logoPosition.x.toFixed(5)) || 0,
            y: Number.parseFloat(this.logoPosition.y.toFixed(5)) || 0,
            width: ensureValidLogoSize(this.logoPosition.width),
            height: ensureValidLogoSize(this.logoPosition.height),
          },
        }
      }
      try {
        await EvercamApi.timelapse.createTimelapse(
          this.projectStore.selectedProjectExid,
          payload
        )
        this.mediaHubStore.forceReloadMediaHub()
        this.endMessage = this.$t("content.timelapse.success_message")
      } catch (error) {
        this.endMessage =
          "Sorry, we can not process your request (" + error.message + ")"
        this.timelapseCreationError = true

        this.$errorTracker.saveAndNotify({
          ERROR: error,
          REQUEST_PAYLOAD: payload,
          FEATURE: "timelapse",
        })
      } finally {
        this.timelapseStore.stepper++
      }
    },
    openCustomScheduleDialog() {
      if (this.scheduleType === TimelapseScheduleType.Custom) {
        this.scheduleDialog = !this.scheduleDialog
      }
    },
    submitChange: debounce(async function () {
      if (this.isDatePickerError === 1) {
        return
      }

      await this.timelapseStore.fetchSnapshots(
        this.cameraStore.selectedCameraExid
      )

      if (
        this.timelapseStore.snapshots != null &&
        !this.timelapseStore.snapshots.length
      ) {
        this.$notifications.error({
          text: this.$t("content.timelapse.no_snapshot_found"),
        })
        this.isDatePickerError = 2
      } else {
        this.isDatePickerError = 0
      }
    }, 500),
    openExportDialog() {
      this.timelapseCreationError = false
      this.timelapseStore.saveDialog = true
    },
    clearEndMessage() {
      this.endMessage = ""
    },
    onResize(rect) {
      if (rect === undefined) {
        this.$setTimeout(this.onResize, 200)

        return
      }
      const containerBox = this.$refs.container?.getBoundingClientRect()

      if (window.innerWidth > 960) {
        this.containerStyle = {
          height: `${window.innerHeight - containerBox.top}px`,
        }
      } else {
        this.containerStyle = {
          height: window.innerHeight * 0.5,
        }
      }
    },
  },
})
