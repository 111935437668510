
export default {
  name: "CtkDateTimePicker",
  props: {
    ctkdatetime: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      datetime: this.ctkdatetime,
    }
  },
}
